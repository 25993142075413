import { Button, ConfigProvider, Row } from "antd"
import Telegram from "@/components/Connect/Telegram";
import './index.scss'
import LineConnect from "../Connect/Line";
import { useTranslation } from "react-i18next";
import { checkSignIn, dailySignIn } from "@/util/fetches";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userSlice } from "@/store";

// 'var(--bg-linear-gradient-color))'
const ToolBarHeader = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const handleSignin = async () => {
        const resp = await dailySignIn();
        if (resp.data.success) {
            setHasSignIn(true)
            dispatch(userSlice.actions.updateSignInStatus())
        }
    }        

    const [hasSignIn, setHasSignIn] = useState(false);
    useEffect(() => {
        checkSignIn().then((resp) => {
            setHasSignIn(resp.data.data);
        })
    }, [])

    return (
        <Row className="p-3 tool-bar-header">
            <ConfigProvider theme={{
                token: {
                    colorPrimary: 'rgb(255, 117, 0)',
                    colorBgContainerDisabled: 'rgba(255, 117, 0, 0.2)',
                }
            }}>
                <Button
                    type="primary"
                    shape="round" // 圆形按钮
                    size="large" // 大号按钮
                    onClick={handleSignin}
                    className="sign-in-btn"
                    disabled={hasSignIn}
                >
                    {hasSignIn ? t('已签到') : t('签到')}
                </Button>
            </ConfigProvider>
            <div className="tg-header flex items-center justify-end flex-wrap">
                <label className="text-white fs-6 me-3 mr-4 sm:hidden">{t('Connect Us')}:</label>
                <div className="sm:hidden">
                    <LineConnect />
                </div>
                <div className="hidden sm:block">
                    <LineConnect text="Join Line" />
                </div>
                <Telegram />
            </div>
        </Row>)
}

export default ToolBarHeader;