/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
// import 'react-phone-number-input/style.css'

// import "react-inputs-validation/lib/react-inputs-validation.min.css";

import telegramIcon from '@/assets/telegram.svg';
import Dropdown from 'react-bootstrap/Dropdown';
// import malaysia_flag from '@/assets/malaysia_flag.png';
import malaysia_flag from '@/assets/malaysia_flag.svg';
import indonesia_flag from '@/assets/indonesia_flag.png';
import philippine_flag from '@/assets/philippine_flag.png';
import cambodia_flag from '@/assets/cambodia_flag.png';
import tw_flag from '@/assets/china_tw_flag.svg';
import singapore_flag from '@/assets/singapore_flag.svg';
import us_flag from '@/assets/us-rounded.svg';
// import "react-custom-flag-select/lib/react-custom-flag-select.min.css";

import './Telegram.scss'
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { getCommunities } from '@/util/fetches';
import LineConnect from './Line';

const getFlag = (region: string) => {
    switch (region) {
        case 'Taiwan':
            return tw_flag;
        case 'Singapore':
            return singapore_flag;
        case 'United States':
            return us_flag;
        case 'Malaysia':
            return malaysia_flag;
        case 'Indonesia':
            return indonesia_flag;
        case 'Philippine':
            return philippine_flag;
        case 'Cambodia':
            return cambodia_flag;
        default:
            return malaysia_flag;
    }
}


const Connect = () => {
    let { t } = useTranslation();

    const { data: communities, isFetching, isLoading } = useQuery({
        queryKey: ['api/ace/public/communities'],
        queryFn: getCommunities,
    })

    useEffect(() => {
        // console.log('communities', communities)
    }, [communities])

    return (
        <div className='drop-down'>
            <div className="flex gap-3 ant-col me-0 me-lg-3 footer-connect-us d-connect-us items-center">
                <Dropdown
                    data-bs-theme="dark"
                >
                    <Dropdown.Toggle id="dropdownMenuTelegram" className="py-1.5 px-2 btn-secondary flex justify-center items-center h-[38px] sm:h-[32px]">
                        <div className='flex'>
                            <img className="telegram-icon ms-0 me-2" width={22} src={telegramIcon} alt="telegram icon" />
                            <label className="text-white fz-connect-us sm:hidden">{t('Select community group')}</label>
                            <label className="text-white fz-connect-us hidden sm:block">{t('Join Telegram')}</label>
                        </div>
                        {/* <span className="dropdown-arrow down">
                            <img className="telegram-icon ms-3 ml-4" src={arrowDownIcon} alt="arrow down icon" />
                        </span> */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu id='menuTelegram' className="w-100 mt-1 dropdown-menu" >
                        {(Array.isArray(communities?.data) ? communities.data : []).map((community, index) => {
                            return (
                                <Dropdown.Item key={community.name} href={community.link}>
                                    <img className="me-1 tg-flag" src={getFlag(community.region)} alt="" />
                                    <label className="cursor-pointer ms-2">{t(community.name)}</label>
                                </Dropdown.Item>)
                        })}
                        {/* <Dropdown.Item href="https://t.me/+VNL0t5ZwLZY1NDll">
                            <img className="me-1"
                                src={malaysia_flag}
                                alt="" />
                            <label className="cursor-pointer ms-2">Malaysia</label></Dropdown.Item>
                        <Dropdown.Item href="https://t.me/EVOHackersID"><img className="me-1"
                            src={indonesia_flag}
                            alt="" /><label className="cursor-pointer ms-2">Indonesia</label></Dropdown.Item>
                        <Dropdown.Item href="https://t.me/+q9AQtoZMAHE4Mzk1"><img className="me-1"
                            src={philippine_flag}
                            alt="" /><label className="cursor-pointer ms-2">Philippine</label></Dropdown.Item>
                        <Dropdown.Item href="https://t.me/EvoHackerCambodia"><img className="me-1"
                            src={cambodia_flag}
                            alt="" /><label className="cursor-pointer ms-2">Cambodia</label></Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}

export default Connect;