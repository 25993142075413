import { Button } from "antd";
import lineRegister from "@/assets/line_register.png";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

const LineConnect = ({
    text = "Join Line Community",
    disabled = false
}) => {


    const onClick = useCallback(() => {
        window.location.href = `https://liff.line.me/1645278921-kWRPP32q/?accountId=acehackers`;
    }, []);    

    const { t } = useTranslation();

    return (
        <Button 
            onClick={onClick}
            disabled={disabled} 
            htmlType='submit' type="primary" 
            className="btn hstack btn-custom-Line justify-center items-center mr-4 sm:mr-0 h-[38px] sm:h-[32px] sm:text-sm" style={{ backgroundColor: "rgb(88, 191, 56)" }}>
            <img width="22" src={lineRegister} className="me-2 me-md-0" alt="" />
            <div className="vr mx-2 d-none d-md-block"></div>
            <span style={{ color: "rgb(255, 255, 255)" }}>{t(text)}</span>
        </Button>
    )
}

export default LineConnect;